<template>
  <v-layout class="rounded rounded-md">
    <v-navigation-drawer width="200" color="#37474F" permanent>
      <div class="d-flex justify-center px-4 py-10">
        <NuxtLink :to="localePath('/')" class="web-logo">
          <img src="~/assets/images/ug-logo.png" alt="UG logo" class="logo">
        </NuxtLink>
      </div>
      <v-list v-model:opened="openGroup" class="admin-nav-list">

        <v-list-group :value="title" v-for="({ title, icon, items }) in AdminMenu">
          <template v-slot:activator="{ props }">
            <v-list-item
              v-bind="props"
              :prepend-icon="icon"
              :title="title"
              base-color="#fff"
              variant="plain"></v-list-item>
          </template>

          <template v-for="(report, i) in filterByPrivilege(items)">
            <v-divider v-if="isMenuDivider(report)"></v-divider>
            <v-list-item v-else :key="i" :title="report.title" :value="report.routerName" :to="localePath({ name: report.routerName })" base-color="#fff" variant="text"></v-list-item>
          </template>

        </v-list-group>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar color="#FAFAFA" class="admin-v-app-bar" flat>
      <template v-slot:append>
        <v-btn :to="localePath('/')" variant="text">UG首頁</v-btn>
        <v-btn variant="text" @click="useLogout">登出</v-btn>
      </template>
    </v-app-bar>

    <v-main style="min-height: 100vh;">
      <slot />
    </v-main>
  </v-layout>
</template>

<script lang="ts" setup>
import { AdminMenu, isMenuDivider, filterByPrivilege } from '@/lib/menu'
const localePath = useLocalePath()

const openGroup = ref(['月報管理'])

</script>

<style lang="stylus" scoped>
.admin-v-app-bar
  border-bottom 1px solid #E0E0E0
.web-logo
  width 30px
  img.logo
    width 100%
.admin-nav-list
  :deep(.v-list-group)
    .v-list-item__prepend
      width 40px
    .v-list-item__append
      width 24px
    .v-list-item__append > .v-icon
      margin-inline-start 0
    .v-list-item-title
      font-size 16px
    .v-list-group__items .v-list-item
      padding-inline-start 56px!important
</style>